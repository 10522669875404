<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <UserForm
      v-if="!isLoading"
      :user="user"
      v-on:addOrUpdateUser="addOrUpdateUser()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import UserForm from "@/components/users/UserForm.vue";
import User from "./../../models/users/User";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    UserForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      user: new User(),
    };
  },
  methods: {
    async addOrUpdateUser() {
      this.isLoading = true;
      try {
        const response = await this.user.addOrUpdateUser(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.user.setInitialValue();
          this.user.userTypeToken = this.$route.meta.userTypeToken;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  created() {
    this.user.userTypeToken = this.$route.meta.userTypeToken;
  },
};
</script>

<style scoped lang="scss"></style>
